
import UserForm from './UserForm';
import './Register.css';

const Register = () => {


    return (
        <>
         <div className="registro-container">
            <UserForm /> 
         </div>
        </>
    );
};

export default Register;

